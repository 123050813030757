export default {
  colors: {
    primary: '#B19A5C',
    background: '#122932',
    shape: `#F2F2FA`,
    title: `#B19a5c`,
    text: `#fff`,
    components: {
      blockquote: {
        background: `#099`,
        text: `#2d3748`,
      },
    },
  },
};
